import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/faros-ai.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/work-log.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/ci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const FarosPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Faros AI vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Faros.ai and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Faros AI vs. Swarmia</>}
        content="Faros suits organizations needing customizable but maintenance-heavy views, whereas Swarmia offers a lighter setup, actionable dashboards and automations serving the whole engineering organization."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Why modern engineering organizations choose Swarmia over Faros AI"
        content="While Faros AI and Swarmia are tackling the same problems, there are fundamental differences that make Swarmia a better fit for modern software organizations."
      />
      <QuoteMosaicBlock
        title="Get insights faster"
        content={
          <>
            Swarmia is designed for quick setup and delivers actionable insights
            faster, making it ideal for modern teams seeking results in days,
            not months. Organizations can choose to start using Swarmia as a
            self-serve product independently, without sales or onboarding calls.
            <br />
            <br />
            In contrast, Faros AI requires extensive setup and maintenance,
            which can delay the realization of valuable insights.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.tim}
      />
      <QuoteMosaicBlock
        title="Less complexity, more accuracy"
        content={
          <>
            Swarmia models the effort of each software engineer based on their
            GitHub contributions and issue tracker activity. Since engineering
            teams often use Swarmia in their daily work, our data quality is
            generally higher than that of competing solutions.
            <br />
            <br />
            Faros gathers data from various sources, making maintaining
            consistent data quality complex and challenging.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.faros1}
      />
      <QuoteMosaicBlock
        title="Intuitive to use"
        content={
          <>
            Swarmia offers intuitive dashboards that are easy to understand but
            can also provide granular detail when needed. On the other hand,
            Faros AI presents more complex dashboards, which require significant
            time and resources to configure and analyze.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.maryanna}
      />
      <QuoteMosaicBlock
        title={<>Solutions for everyone in the engineering organization</>}
        content={
          <>
            Swarmia has tools and automations that help the entire engineering
            organization move faster.
            <br />
            <br />
            In contrast, Faros AI is primarily designed for directors and
            managers in large enterprises to track developer productivity. Faros
            measures developer productivity with vanity metrics like lines of
            code, which can feel like more like surveillance than support.
            <br />
            <br />
            The approach can lead to a disconnect with the needs of individual
            developers and teams using Faros.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        quote={quotes.faros2}
      />
      <QuoteBlock person="roland" />
      <CTABlock title={<>Get started with Swarmia today.</>} />
      <LeadBlock
        heading="Enterprise-grade features meet lightweight setup"
        content="Swarmia combines the best of both worlds: enterprise-grade features with flexible self-service configuration that only takes minutes."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and flexible data exports, Swarmia fits the needs of large and growing organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and roll out."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title={<>Get started with Swarmia today.</>} />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/size-age-culture-productivity/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default FarosPage
